import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const QueryInterface = lazy(() => import("./pages/query.interface"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/queryInterface" element={<QueryInterface />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
